import clsx from "clsx";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { getConnectedEntries } from "../../lib/prismaQueries/getConnectedEntries";
import AllBookingsCount from "./components/AllBookingsCount";
import { useSession } from "next-auth/react";
import SinglePost from "./components/SinglePost";

type Props = {
  entry: NonNullable<
    Awaited<ReturnType<typeof getConnectedEntries>>
  >["entries"][number];
  archived?: boolean;
  hideVariant?: boolean;
};

export const UnconnectedPosts = ({
  entry,
  archived,
  hideVariant = false,
}: Props) => {
  const { data: session } = useSession();

  const router = useRouter();

  return (
    <article className="my-6 mb-12 flex justify-center">
      <div
        className={clsx(
          "relative block w-full rounded-[5px] bg-slate-200 p-6 dark:bg-slate-800"
        )}
      >
        <AllBookingsCount
          count={entry.BookEntry?.length}
          capping={entry.capping}
          etype={entry.etype}
        />
        <h2 className="mb-1 inline w-full font-display text-lg font-medium leading-tight text-slate-700 md:mb-1 md:text-2xl dark:text-white">
          {new Intl.DateTimeFormat(router.locale, {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          }).format(new Date(entry.bookDate))}
        </h2>
        <div className="mt-8">
          <SinglePost
            entry={entry}
            userId={session?.user?.id}
            key={entry.id}
            connectedEntryId={Infinity}
            remainingBookings={entry.capping - entry.BookEntry?.length}
            etype={entry.etype}
            archived={archived}
            reducedPrice={session?.user?.reducedPrice}
            hideVariant={hideVariant}
          />
        </div>
      </div>
    </article>
  );
};
